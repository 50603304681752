.install-instructions {
  background-color: $c-surface_surface_white;
  border-radius: 4px;
  border: 1px solid $c-obsolete-grey_40;
  text-align: left;

  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;

  .install-instructions-header {
    padding: 0rem 1.6rem;
    .install-instructions-title {
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 3.2rem;
      padding-top: 2.4rem;
    }
  }
  .install-instructions-content {
    padding: 0rem 2rem 1.6rem 2rem;
    .p-button {
      .svg-inline--fa {
        padding-right: 0.4rem;
        width: 2rem;
      }
    }
    .p-inputtext {
      margin: 0;
      width: 334px;
      height: 40px;
    }
    .clipboard > span {
      line-break: anywhere;
      white-space: normal !important;
    }

    .p-button-secondary {
      border: none;
      background: $c-obsolete-grey_40;
    }
  }

  .p-tabmenu .p-menuitem-link {
    color: $c-primary_dark_blue;
  }
}
.security {
  color: $c-status_red_100;
  font-weight: bold;
}

.page-entity-detail {
  .p-tag{
    display: inline-block;
    vertical-align: middle;
  }
  .p-tag-icon {
    margin-right: 0.4rem;
  }
  .server-avatar {
    background-color: $c-surface_surface_white;
    color: $c-primary_dark_blue;
    .p-avatar-icon {
      font-size: 4rem;
    }
  }
}

.page-servers {
  .long-text {
    line-height: 2.2rem;
  } 
  .server-list-avatar {
    background-color: $c-surface_surface_white;
    border-style: solid;
    border-color: $c-surface_surface_50;
    color: $c-primary_dark_blue;
    .p-avatar-icon {
      font-size: 1.6rem;
    }
    margin-right: 0.5rem;
  }
}
