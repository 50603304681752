.header-panel {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: solid 1px $c-obsolete-grey_40;
  background-color: $c-surface_surface_white;

  .p-toolbar {
    @include wrapper;
  }

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  .p-toolbar-group-left {
    flex-grow: 5;
  }
  .p-toolbar-group-right {
    flex-grow: 2;
    & > span {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}

.header-panel-with-breadcrumb {
  padding: 2rem 0rem;
  width: 100%;
  overflow: auto;

  .header-area {
    text-align: left;
    padding: 0rem 3.2rem;
    max-width: 118rem;
    margin: 0 auto;
  }
  .breadcrumb-label {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2rem;
    color: $c-hyperlink_default;
  }
  .breadcrumb-icon {
    margin: 0rem 0.7rem 0rem 0.8rem;
  }
}
