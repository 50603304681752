.page-system {
  .toolbar-text-large {
    margin-top: 0.5rem;
    font-size: 2.5rem;
    text-align: left;
  }
  .toolbar-text-normal {
    font-size: 1.4rem;
    text-align: left;
    margin-top: 3rem;
  }

  .labelBold {
    font-weight: 600;
  }

  .toolbar-button {
    border-radius: 4px;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &:hover {
      background-color: rgba($c-surface_surface_white, 0.2);
      cursor: pointer;
    }
  }
  .toolbar-panel {
    margin-top: 1.5rem;
    width: 292px;
    height: 112px;
    border-radius: 4px;
    /* 01 Surface/white_20 */
    background: rgba(255, 255, 255, 0.2);
    /* Shadow-bottom */
    box-shadow: 0px 1px 3px $box-shadow;
    .header-text {
      font-size: 1.4rem;
      text-align: center;
      margin-top: 0.5rem;
      padding-top: 1.8rem;
    }
    .body-text {
      font-size: 2rem;
      text-align: center;
      margin-top: 0.5rem;
      padding-top: 0.5rem;
    }
    .footer-text {
      font-size: 1.4rem;
      text-align: right;
      margin-top: 0rem;
      padding-top: 0.5rem;
    }
  }
  .warning {
    color: $c-status_orange_100;
    width: 24px;
    height: 24px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    padding-right: 4px;
  }
}
