.invite-users-dialog {
  .p-datatable-thead>tr>th {
    padding: 0;
    border: 0;
    color: $c-primary_dark_blue;
    font-size: 14px;
    font-weight: 400;
  }

  .p-datatable-tbody>tr>td {
    padding: 0;
    padding-top: 8px;
    border: 0;

    input.p-inputtext,
    .p-dropdown {
      width: calc(100% - 8px);
    }
  }
}