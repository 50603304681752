.top-bar {
  position: fixed;
  left: 0;
  top: 0;
  height: 10.4rem;
  width: 100%;

  padding: 0 2.4rem;
  color: $c-surface_surface_white;
  z-index: 2;

  .background-container {
    position: absolute;
    background-color: $c-primary_dark_blue;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    .mask-left,
    .mask-right {
      position: absolute;
      filter: blur(100px);
      background: url("../../../public/img/mask-shieldoo.png");
    }

    .mask-left {
      width: 683px;
      height: 728px;
      left: -303px;
      top: -162px;
    }

    .mask-right {
      width: 500px;
      height: 532px;
      top: -21px;
      right: -144px;
      transform: rotate(30deg);
    }
  }

  .top-bar-content {
    display: none;
    @media only screen and (min-width: 1280px) {
      width: 123rem;
    }
    margin: 0 auto;
    .p-button {
      color: $c-primary_dark_blue;
    }
  }

  & + main {
    margin-top: 10.4rem;
    height: calc(100% - 10.4rem);
  }

  &.top-bar-medium {
    height: 24.8rem;

    & + main {
      margin-top: 24.8rem;
      height: calc(100% - 24.8rem);
    }

    .background-container {
      .mask-left {
        height: 1736px;
        top: -386px;
      }

      .mask-right {
        width: 735px;
        height: 1130px;
        top: -500px;
        transform: matrix(0.93, 1.28, -0.19, 0.77, 0, 0);
      }
    }

    .top-bar-content {
      display: block;
      margin-top: 3.6rem;
    }
  }

  &.top-bar-large {
    height: 34.5rem;

    .background-container {
      .mask-right {
        top: 95px;
        right: -144px;
      }
    }

    .top-bar-content {
      display: block;
      margin-top: 3.6rem;
    }

    & + main {
      margin-top: 34.5rem;
      height: calc(100% - 34.5rem);
    }
  }

  &.top-bar-full {
    height: calc(100%);

    .background-container {
      .mask-right {
        top: 95px;
        right: -144px;
      }
    }

    .top-bar-content {
      display: block;
      margin-top: 3.6rem;
    }

    & + main {
      margin-top: 0;
      height: 0;
    }
  }

  .top-bar-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2.4rem;

    .p-menuitem {
      .p-menuitem-link {
        opacity: 0.84;
      }

      &.p-highlight {
        background-color: $c-surface_surface_white;

        .p-menuitem-link {
          opacity: 1;
          color: $c-primary_dark_blue;
        }
      }
    }
  }

  .account-menu {
    display: flex;
    align-items: center;
    padding: 0.4rem 0.8rem;
    .p-button {
      padding: 0rem 0rem 0rem 0.8rem;
    }
  }
  .account-menu-active,
  .account-menu:hover {
    cursor: pointer;
    background-color: rgba($c-surface_surface_white, 0.1);
    border-radius: 4px;
  }
  .user {
    text-align: right;

    .greeting {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 300;

      .user-name {
        font-weight: 500;
      }
    }
    .user-role {
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 500;
      color: $c-primary_teal_default;
    }
  }

  .p-avatar {
    margin-right: 0.4rem;
  }

  .p-button {
    color: $c-surface_surface_white;
  }

  .fa,
  .fa-regular {
    // font-size: 1.2rem;
    line-height: 2.4rem;

    &.fa-chevron-down {
      transition: all 0.2s;
    }
  }

  .fa-chevron-down {
    width: 24px;
    height: 24px;
  }

  .p-tabmenu {
    position: absolute;
    bottom: 0;
  }
}

.top-bar-left,
.top-bar-right {
  display: flex;
  align-items: center;
  z-index: 5;
}

.logout-button {
  background: transparent;
  border: none;
  color: red;
  font-weight: 700;
  font-size: 18px;
}

.empty-icon {
  margin-right: 24px !important;
}
