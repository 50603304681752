.p-dialog-mask {
  background-color: rgba($c-primary_dark_blue, .72) !important;
}

.p-dialog {
  background-color: $c-surface_surface_white;
  border-radius: 4px;
  padding: 2rem;
  box-shadow: 0 1px 3px $box-shadow;
  font-size: 1.4rem;

  .p-dialog-header-icons {
    align-self: auto;
  }
}

.p-dialog-header {
  margin-bottom: 2rem;
}

.p-dialog-footer {
  margin-top: 3rem;
  text-align: right;

  .p-button {
    font-weight: 500;
  }
}

.p-dialog-title {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3.2rem;
}
