a, .hyperlink {
  color: $c-hyperlink_default;
  text-decoration: none;
  font-weight: bold ;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.0);
}

h2 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 400;
}

h3 {
  font-size: 1.8rem;
  line-height: 1.8rem;
  font-weight: 400;
}
.highlight {
  color: $c-primary_teal_default;
}
.highlight-dark {
  color: $c-primary_teal_hover;
}
.fa-10x {
  font-size: 10rem;
}
.fa-9x {
  font-size: 9rem;
}
.fa-8x {
  font-size: 8rem;
}
.fa-7x {
  font-size: 7rem;
}
.fa-6x {
  font-size: 6rem;
}
.fa-5x {
  font-size: 5rem;
}
.fa-4x {
  font-size: 4rem;
}
.fa-3x {
  font-size: 3rem;
}
.fa-2x {
  font-size: 2rem;
}